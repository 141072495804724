import { useTranslation } from "react-i18next";
import LoadingSpinner from "../components/UI/loadingSpinner/LoadingSpinner";
import CustomTable from "../components/tables/customTable/CustomTable";
import { customers, usersHeader } from "../constants/tables";
import useDataFetch from "../hook/useDataFetch";
import { IProposalTable, IusersTable } from "../interfaces/Itable";
import { Flex, Heading } from "@chakra-ui/react";
import Button from '../components/UI/button/Button';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Register from "./Register";
const url = `${process.env.REACT_APP_API_URL}/api/users`;

function Users() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, error, loading, refetch } = useDataFetch<IusersTable[]>(url);
  const [createUser, setCreateUser] = useState(false)
  const [selecteduser, setSelectedUser] = useState<string>("");
  const portalProposalUrl = `${process.env.REACT_APP_API_URL}/api/portal/proposals`

  const { data: proposalData } = useDataFetch<IProposalTable[]>(portalProposalUrl);
  
let userTable;


  useEffect(() => {
    if (selecteduser) refetch()
  }, [selecteduser])


 if (loading) {
    userTable = <LoadingSpinner />;
  }

  if (error) {
    userTable = (
      <CustomTable limit={10} headData={usersHeader} bodyData={[]} setSelected={setSelectedUser} />
    );
  }


  if (!loading && data && proposalData) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

    // Map data to include currentMonthRevenue and lastMonthRevenue for each user
    const dataWithRevenue = data.map(user => {
      // Filter proposals for the current month
      const currentMonthProposals = proposalData.filter(proposal => {
        const proposalDate = new Date(proposal.createdAt);
        return (
          proposal.creator._id === user._id &&
          proposalDate.getMonth() === currentMonth &&
          proposalDate.getFullYear() === currentYear
        );
      });

      // Filter proposals for the last month
      const lastMonthProposals = proposalData.filter(proposal => {
        const proposalDate = new Date(proposal.createdAt);
        return (
          proposal.creator._id === user._id &&
          proposalDate.getMonth() === lastMonth &&
          proposalDate.getFullYear() === lastMonthYear
        );
      });

      // Calculate revenue for the current month and last month
      const currentMonthRevenue = currentMonthProposals.reduce(
        (acc, proposal) => acc + proposal.remianingCost,
        0
      );

      const lastMonthRevenue = lastMonthProposals.reduce(
        (acc, proposal) => acc + proposal.remianingCost,
        0
      );

      return {
        ...user,
        currentMonthRevenue,
        lastMonthRevenue,
      };
    });
    const sortedData = dataWithRevenue.sort((a, b) => a?.name.localeCompare(b?.name));
    userTable = (
      <CustomTable limit={10} headData={usersHeader} bodyData={sortedData} setSelected={setSelectedUser} />
    );
  }

  return (
    <section>
      {/* <h2 className="title">{t("users")}</h2> */}

      <Flex mb={4} alignItems="center" justify="space-between">

        <Heading color="#F36E21">  {t("users")}</Heading>
        {createUser ? <Button outline onClick={() => setCreateUser(!createUser)}>
          {t('Go Back')}
        </Button>
          :
          <Button outline onClick={() => setCreateUser(!createUser)}>
            {t('createUser')}
          </Button>}

      </Flex>
      {createUser ? <Register /> : userTable}
    </section>
  );
}

export default Users;

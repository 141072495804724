import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, List, ListIcon, ListItem, ModalBody, ModalFooter, Select as Shivam, useToast } from '@chakra-ui/react';
import { IPortalProfileTable, IusersTable } from '../../../../interfaces/Itable';
import { Select } from "chakra-react-select";
import useDataFetch from '../../../../hook/useDataFetch';
import api from '../../../../utils/api';
import  { AxiosError } from 'axios';
import { MonthlyStats } from '../../../../interfaces/IsummData';
import LoginContext from '../../../../store/loginContext';
import { v4 as uuidv4 } from 'uuid';

const EditUser = ({ onClose, item ,setSelected}: { onClose: () => void; item: IusersTable ,setSelected?:React.Dispatch<React.SetStateAction<string>>}) => {
  const { user } = useContext  (LoginContext);
  const [editedItem, setEditedItem] = useState<IusersTable>(item);
  const [selected1, setSelected1] = useState<{
    value: string;
    label: string;
  }[]>(item.profilesAllocated.map((profile: any) => ({
    value: profile._id,
    label: profile.profileName,
  })));
  const toast = useToast()

// Determine the latest target
const latestTarget = item.target?.reduce((latest, current) => {
  return new Date(latest.targetDate) > new Date(current.targetDate) ? latest : current;
}, item.target[0]);

const [targetAmt, setTargetAmt] = useState<number | string>(latestTarget ? latestTarget.targetAmt : '');

const [targetDate, setTargetDate] = useState<string>(
  latestTarget ? new Date(latestTarget.targetDate).toISOString().split("T")[0] : ""
)

  useEffect(()=>{
setSelected1(item.profilesAllocated.map((profile: any) => ({
  value: profile._id,
  label: profile.profileName,
})))
  },[item.profilesAllocated])
  
  const PortalProfileUrl=`${process.env.REACT_APP_API_URL}/api/portal/profile`
  const StatsUrl=`${process.env.REACT_APP_API_URL}/api/portal/stats?id=${item._id}`

  const { data:stats } = useDataFetch<MonthlyStats>(StatsUrl);
  const { data } = useDataFetch<IPortalProfileTable[]>(PortalProfileUrl);






  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
setEditedItem((prev) => ({
        ...prev,
        [name]: value,
      }));
  
  };
  
  const handleTargetAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTargetAmt(e.target.value);
  };


 const { monthly , weekly } = stats || {};

  const handleEdit = async() => {
    try {
      // const currentDate = new Date().toISOString();
      const formatedDate = new Date(targetDate).toISOString()
      console.log(typeof formatedDate,"cureentDate")
      const updatedItem = { 
        ...editedItem, 
        target: [{ targetAmt: Number(targetAmt), targetDate: formatedDate,targetId: uuidv4(), }] // Set target with current date
      };

      console.log(updatedItem,"item")
    
      const res = await api.put(`/users/${item._id}`, updatedItem);
      if (res.data) {
        if (setSelected) {
          setSelected(Date.now().toString());
        }
    
        toast({
          title: "User edit successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (err) {
     
      if(err instanceof AxiosError){
          
        toast({
          description: err.response?.data?.message || 'An unknown error occurred.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
      else{
        console.log(err);
      }
    }
    onClose();
  };

  const handleMulti = (value: { value: string; label: string }[]) => {
    const selectedIds = value.map((option) => option.value);
    setSelected1(value);
    setEditedItem({ ...editedItem, profilesAllocated: selectedIds });
  };
  
  const showRoles = user?.role==="manager"? ["member","manager"] : ["admin","member","manager"];




  return (
    <>
      <ModalBody pb={6}>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            name="name"
            value={editedItem.name}
            onChange={handleChange}
            readOnly
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Target</FormLabel>
          <Input
           type="number"
            // value={editedItem.target || ''}
            name="targetAmt"
            value={targetAmt}
            onChange={handleTargetAmountChange}
      
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Date</FormLabel>
          <Input
            type="date"
            name="targetDate"
            value={targetDate}
            onChange={(e)=>setTargetDate(e.target.value)}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>Role</FormLabel>
          <Shivam
            placeholder='Select option'
            name='role'
            value={editedItem.role}
            onChange={handleChange}
          >
            {showRoles.map((el) => (
              <option key={el} value={el}>
                {el}
              </option>
            ))}
          </Shivam>
        </FormControl>

        {data && (
     <FormControl mt={4}>
     <FormLabel>Profile Allocated</FormLabel>
     <Select
       isMulti
       name="jobCategoryId"
       onChange={(value) => handleMulti(value as any)}
       value={selected1}
       colorScheme="purple"
       options={data?.map((el) => ({
         value: el._id,
         label: el.profileName,
       })) as any}
       placeholder="Select some categories..."
       closeMenuOnSelect={false}
     />
   </FormControl>
        )}

{stats && <Box>
  <List mt={4} spacing={3}>
  <ListItem>
    <ListIcon color='green.500' />
    Weekly Used Connects:{weekly?.totalConnects}
  </ListItem>
  <ListItem>
    <ListIcon  color='green.500' />
    Weekly Total Sales:{weekly?.totalSales}
  </ListItem>
  <ListItem> <ListIcon  color='green.500' />Weekly Recieved Amount:{weekly?.totalReceivedAmount}</ListItem>
  <ListItem> <ListIcon  color='green.500' />Monthly Used Connects:{monthly?.totalConnects}</ListItem>
  <ListItem> <ListIcon  color='green.500' />Monthly Total Sales:{monthly?.totalSales}</ListItem>
  <ListItem> <ListIcon  color='green.500' />Monthly Recieved Amount:{monthly?.totalReceivedAmount}</ListItem>
  </List>
  </Box>}

      </ModalBody>

      <ModalFooter>
        <Button bg= "#F36E21" color="white" _hover={{ color:"black",bg:"#dfd6d68a"}} mr={3} onClick={handleEdit}>
          Edit
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default EditUser;
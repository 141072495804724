import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          member: "member",
          admin: "admin",
          dashboard: "Dashboard",
          orders: "Orders",
          portal:"Portal",
          products: "Products",
          customers: "Customers",
          analytics: "Analytics",
          discount: "Discount",
          inventory: "Inventory",
          logout: "Logout",
          login: "Login",
          summary: "Summary",
          thisMonthSales: "This month Sales",
          thisMonthOrders: "This month Orders",
          thisMonthRevenue: "This month Revenue",
          thisYearRevenue: "This year Revenue",
          thisTotalRevenue: "Total Revenue",
          quickAnalysis: "Quick Analysis",
          topCustomers: "Top Customers",
          latestTransaction: "Latest Transactions",
          customer: "Customer",
          totalSpending: "Total Spending",
          totalOrders: "Total Orders",
          orderID: "Order ID",
          proposalURL: "Proposal URL",
          totalPrice: "Total Price",
          date: "Date",
          status: "Status",
          approved: "Approved",
          pending: "Pending",
          rejected: "Rejected",
          viewAll: "View All",
          search: "Search",
          editCustomer: "Edit Customer",
          editProduct: "Edit Product",
          AccountDetails: "Account Details",
          contacts: "Contacts",
          edit: "Edit",
          userName: "User Name",
          pass: "Password",
          phoneNumber: "Phone Number",
          email: "Email",
          address: "Address",
          upload: "Upload",
          location: "Location",
          deleteCustomer: "Confirm Deletion",
          modalMessage: "Are you sure about delete this?",
          delete: "Delete",
          cancel: "Cancel",
          actions: "Actions",
          category: "Category",
          all: "All",
          clothing: "Clothing",
          digital: "Digital",
          beauty: "Beauty",
          product: "Product",
          price: "Price",
          proName: "Product Name",
          inventoryCount: "Inventory Count",
          loginPage: "Login",
          registerPage: "Create Your Account",
          errorMessage: "Please enter 'admin' in User Name box",
          forgetPass: "Forget your password?",
          forgot:"Forgot Password",
          resetpassword:"Reset Password",
          rememberMe: "Remember me",
          salesAmount: "5,340",
          orderAmount: "3000",
          revenueAmount: "2,500",
          currency: "$",
          summaryOfSale: "Chart Of Sale",
          summaryOfRevenue: "Chart Of Revenue",
          summaryOfOrders: "Chart Of Order",
          Jan: "Jan",
          Feb: "Feb",
          Mar: "Mar",
          Apr: "Apr",
          May: "May",
          Jun: "Jun",
          July: "July",
          Aug: "Aug",
          Sep: "Sep",
          Oct: "Oct",
          Nov: "Nov",
          Dec: "Dec",
          backToHome: "Back to Main Page",
          notFoundMsg: "Page Not Found!",
          clients:"Clients",
          proposals:"Proposals",
          createProposal:"Create Proposal",
          hiredProposal:"Hired Proposal",
          target:"Target",
          currentmonthrevenue:"Current Month Revenue",
          previousmonthrevenue:"Previous Month Revenue",
          reset:"Reset",
          setting:"Setting",
          users:"Users",
          connect:"Used connect",
          Revenue:"Revenue",
          connectCost:"Connects/$",
          RecievedAmount:"Received Amount",
          thisMonthBid:"This month bids",
          thisYearBid:"This year Bids",
          thisWeeklyBid:"This week Bids",
          dailyBid:"Daily Bids",
          TotalBid:"Total Bids",
          dailyconnect:"Daily used connect",
          dailyRevenue:"Daily Revenue",
          dailyUsedMoneyOnConnects:"Daily Connects/$",
          dailyproposals:"Daily proposal",
          dailyRecievedAmount:" Daily received Amount",
          thisWeekConnects: "This week used Connects",
          thisWeekRevenue: "This week Revenue",
          thisweeklyProposals: "This week Proposals",
          thisWeeklyRecievedAmount: "This week received Amount",
          thisWeekUsedMoneyOnConnects: "This week Connects/$",
          thisMonthConnects:"This month used Connects",
          thisYearConnects:"This year used Connects",
          thisTotalConnects:"Total used Connects",
          thisMonthProposals:"This month Proposals",
          thisYearProposals:"This year Proposals",
          thisTotalProposals:"Total Proposals",
          thisMonthRecievedAmount:"This month recieved Amount",
          thisYearRecievedAmount:"This year recieved Amount",
          thisTotalRecievedAmount:"Total recieved Amount",
          thisMonthUsedMoneyOnConnects:"This month Connects/$",
          thisYearUsedMoneyOnConnects:"This year Connects/$",
          thisTotalUsedMoneyOnConnects:"Total month Connects/$",
          profile: "Profile",
          upsell:"Upsell",
          chat:"Chat",
          cost: "Cost",
          connectsUsed: "Connects",
          client: "Client",
          createUser:"Create User",
          createdOn:"Created On",
      name:"Name",
      password:" New Password",
      confirmPassword:"Confirm Password",
      update_proposal:"Update Proposal",
      create_proposal:"Create Proposal",
      save:"Save",
      createUpsell:"Create Upsell"
        },
      },
      hi: {
        translation: {
          member: "सदस्य",
          admin: "एडमिन",
          dashboard: "डैशबोर्ड",
          orders: "आर्डर्स",
          portal: "पोर्टल",
          products: "उत्पाद",
          customers: "ग्राहक",
          analytics: "विश्लेषण",
          discount: "डिस्काउंट",
          inventory: "इन्वेंटरी",
          logout: "लॉग आउट",
          login: "लॉगिन",
          summary: "सारांश",
          thisMonthSales: "इस महीने की बिक्री",
          thisMonthOrders: "इस महीने के आर्डर्स",
          thisMonthRevenue: "इस महीने की आय",
          thisYearRevenue: "इस साल की आय",
          thisTotalRevenue: "कुल आय",
          quickAnalysis: "त्वरित विश्लेषण",
          topCustomers: "शीर्ष ग्राहक",
          latestTransaction: "नवीनतम लेन-देन",
          customer: "ग्राहक",
          totalSpending: "कुल खर्च",
          totalOrders: "कुल आर्डर्स",
          orderID: "आर्डर आईडी",
          totalPrice: "कुल मूल्य",
          date: "तारीख",
          status: "स्थिति",
          approved: "मंजूर",
          pending: "बकाया",
          rejected: "अस्वीकृत",
          viewAll: "सभी देखें",
          search: "खोज",
          editCustomer: "ग्राहक संपादित",
          editProduct: "उत्पाद संपादित",
          AccountDetails: "खाता विवरण",
          contacts: "संपर्क",
          edit: "संपादित",
          userName: "उपयोगकर्ता नाम",
          pass: "पासवर्ड",
          phoneNumber: "फ़ोन नंबर",
          email: "ईमेल",
          address: "पता",
          upload: "अपलोड",
          location: "स्थान",
          deleteCustomer: "ग्राहक हटाएं",
          modalMessage: "क्या आप इसे हटाने के बारे में सुनिश्चित हैं?",
          delete: "हटाएं",
          cancel: "रद्द करें",
          actions: "क्रियाएँ",
          category: "श्रेणी",
          all: "सभी",
          clothing: "कपड़े",
          digital: "डिजिटल",
          beauty: "सौंदर्य",
          product: "उत्पाद",
          price: "मूल्य",
          proName: "उत्पाद नाम",
          inventoryCount: "इन्वेंटरी गणना",
          loginPage: "अपने खाते में लॉगिन करें",
          registerPage: "अपने खाते को पंजीकृत करें",
          errorMessage: "कृपया 'एडमिन' को उपयोगकर्ता नाम बॉक्स में दर्ज करें",
          forgetPass: "क्या आपने अपना पासवर्ड भूल गए हैं?",
          rememberMe: "मुझे याद रखें",
          salesAmount: "5,340",
          orderAmount: "3,000",
          revenueAmount: "2,500",
          currency: "₹",
          summaryOfSale: "बिक्री का चार्ट",
          summaryOfRevenue: "आय का चार्ट",
          summaryOfOrders: "आर्डर का चार्ट",
          Jan: "जनवरी",
          Feb: "फरवरी",
          Mar: "मार्च",
          Apr: "अप्रैल",
          May: "मई",
          Jun: "जून",
          July: "जुलाई",
          Aug: "अगस्त",
          Sep: "सितंबर",
          Oct: "अक्टूबर",
          Nov: "नवंबर",
          Dec: "दिसंबर",
          backToHome: "मुख्य पृष्ठ पर वापस",
          notFoundMsg: "पृष्ठ नहीं मिला!",
          clients: "क्लाइंट्स",
          proposals: "प्रस्ताव",
          createProposal: "प्रस्ताव बनाएं",
          target: "लक्ष्य",
          reset: "रीसेट",
          setting: "सेटिंग",
          users: "उपयोगकर्ताओं",
          thisWeekConnects: "इस हफ्ते का उपयोग किए गए कनेक्ट्स",
          thisWeekRevenue: "इस हफ्ते की आय",
          thisWeeklyProposals: "इस हफ्ते के प्रस्ताव",
          thisWeeklyRecievedAmount: "इस हफ्ते में प्राप्त राशि",
          thisWeekUsedMoneyOnConnects: "इस हफ्ते के कनेक्ट्स/$ पर खर्च",
          thisMonthConnects: "इस महीने का उपयोग किया गया Connects",
          thisYearConnects: "इस साल का उपयोग किया गया Connects",
          thisTotalConnects: "कुल उपयोग किए गए Connects",
          thisMonthProposals: "इस महीने के प्रस्ताव",
          thisYearProposals: "इस साल के प्रस्ताव",
          thisTotalProposals: "कुल प्रस्ताव",
          thisMonthRecievedAmount: "इस महीने प्राप्त राशि",
          thisYearRecievedAmount: "इस साल प्राप्त राशि",
          thisTotalRecievedAmount: "कुल प्राप्त राशि",
          thisMonthUsedMoneyOnConnects: "इस महीने Connects/$ पर खर्च",
          thisYearUsedMoneyOnConnects: "इस साल Connects/$ पर खर्च",
          thisTotalUsedMoneyOnConnects: "कुल में Connects/$ पर खर्च",
          profile: "प्रोफाइल",
          cost: "लागत",
          connectsUsed: "कनेक्ट किए गए",
          client: "ग्राहक",     
          createdOn: "बनाया गया",
          ID: "आईडी",
          clientName: "ग्राहक का नाम",
          phone: "फ़ोन",
          country: "देश",
          skype: "स्काइप",
          invited: "आमंत्रित",
          name: "नाम",
          password: "पासवर्ड",
        },
      },
      
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;

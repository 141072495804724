import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../utils/api";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select as Shivam,
  Stack,
  Textarea,
  useToast
} from '@chakra-ui/react';
import { Select } from "chakra-react-select";
import useDataFetch from "../../hook/useDataFetch";
import { IClientsTable, IJobCategoryTable, IPortalProfileTable, IPortalTable } from "../../interfaces/Itable";
import LoginContext from "../../store/loginContext";
import { useTranslation } from "react-i18next";
import axios from "axios";
import LoadingSpinner from "../UI/loadingSpinner/LoadingSpinner";

interface FormData {
  companyName: string;
  portal: string;
  profile: string;
  jobCategory: string[];
  proposalTitle: string;
  proposalLink: string;
  proposalType: string;
  currency: string;
  cost: number;
  estimatedHours: string;
  grossCost: number;
  receivedAmount: string;
  projectDeadline: string;
  totalConnects: string;
  proposalDesc: string;
  client: string;
  status: string;

}

export default function HiredProposalViewSection() {
  const apiKey = '98a265629f6f37c3eaf2e783';
  const { user } = useContext(LoginContext);
  const url = `${process.env.REACT_APP_API_URL}/api/users/${user._id}`;
  const PortalCategoryUrl = `${process.env.REACT_APP_API_URL}/api/portal/category`
  const PortalUrl = `${process.env.REACT_APP_API_URL}/api/portal`
  const ClientUrl = `${process.env.REACT_APP_API_URL}/api/portal/clients`
  const PortalPorfileUrl = `${process.env.REACT_APP_API_URL}/api/portal/profile`
  const { id } = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [portalName, setportalName] = useState({})
  const [price, setPrice] = useState<number | null>(null);
  const [selectedPortal, setSelectedPortal] = useState<IPortalTable | null>(null);
  const [userdata, setUserdata] = useState(user);
  const [loadingid, setLoadingID] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [clientName, setClientName] = useState('');
  const [rates, setRates] = useState(0);
  const { data: categoryData, } = useDataFetch<IJobCategoryTable[]>(PortalCategoryUrl);
  const { data, loading } = useDataFetch<IPortalTable[]>(PortalUrl);
  const { data: clientData, refetch } = useDataFetch<IClientsTable[]>(ClientUrl);
  const { data: profileData } = useDataFetch<IPortalProfileTable[]>(PortalPorfileUrl);
  const [selected2, setSelected2] = useState<{
    value: string;
    label: string;
  }>({
    value: '',
    label: ""
  });
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({
    companyName: '',
    portal: '',
    profile: "",
    jobCategory: [],
    proposalTitle: '',
    proposalLink: '',
    proposalType: '',
    currency: '',
    estimatedHours: "0",
    cost: 0,
    grossCost: 0,
    receivedAmount: '',
    projectDeadline: new Date().toISOString().split('T')[0],
    totalConnects: '',
    proposalDesc: '',
    client: '',
    status: 'new',

  });

  const handleEditClick = (event:any) => {
    event.preventDefault();
    setIsEditable(true);
  };
  

  const handleCancelClick = () => {
     setIsEditable(false);
    navigate(-1)
  };

  // ______________________getting the updateduserdat_____________________

  const fetchData = async () => {
    try {

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json()


      if (result) {
        setUserdata(result)

      }

    } catch (error: unknown) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData();
  }, [])


  useEffect(() => {

    async function getData() {
      try {

        setLoadingID(true)
        const res = await api.get('/portal/proposal/' + id);
        setportalName(res?.data?.portal?.portalName)

        setLoadingID(false)
        if (!res.data) {
          return
        }
        const {
          companyName,
          portal,
          jobCategory,
          profile,
          proposalTitle,
          proposalLink,
          proposalType,
          currency,
          cost,
          estimatedHours,
          grossCost,
          receivedAmount,
          projectDeadline,
          totalConnects,
          proposalDesc,
          client,
          status,

        } = res.data;
        setSelected2({
          value: client?._id,
          label: client?.clientName
        })
        // Convert jobCategory to an array of _id strings if it's an array
        const jobCategoryIds = Array.isArray(jobCategory)
          ? jobCategory.map((category: any) => category._id.toString())
          : jobCategory?._id
            ? [jobCategory._id.toString()]
            : [];

        setFormData({
          companyName: companyName || '',
          portal: portal?._id || '',
          profile: profile?._id || '',

          jobCategory: jobCategoryIds,
          proposalTitle,
          proposalLink,
          proposalType,
          currency,
          estimatedHours,
          cost,

          grossCost,
          receivedAmount,
          projectDeadline: new Date(projectDeadline).toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
          totalConnects,
          proposalDesc,
          client: client?._id || '',
          status,

        });
      } catch (err) {
        console.log(err);
      }
    }
    if (id) getData();
  }, [id]);


  useEffect(() => {
    if (formData?.portal && data) {
      const selectedPortal = data.find((portal) => portal._id === formData?.portal);
      if (selectedPortal) {
        setSelectedPortal(selectedPortal);
      }
    }
  }, [formData?.portal, data])


  // const pathElements = pathname.split('/').filter((el) => el !== '')


  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {

    const { name, value } = e.target;

    setFormData((prevData) => {

      if (name === 'proposalType' && value === 'fixed') {
        return {
          ...prevData,
          [name]: value,
          estimatedHours: "0",
          cost: 0,
        };
      }
      return {
        ...prevData,
        [name]: value,
      };
    });
  };



  const handleClient = (value: { value: string; label: string }) => {
    setFormData((prevData) => ({
      ...prevData,
      "client": value.value
    }));
    setSelected2(value)


  };


  useEffect(() => {
    // Fetch exchange rates on component mount
    const fetchRates = async () => {
      try {
        const response = await axios.get(`https://v6.exchangerate-api.com/v6/98a265629f6f37c3eaf2e783/latest/${formData?.currency}`);
        setRates(response.data.conversion_rates.USD);

      } catch (error) {
        console.error('Error fetching exchange rates', error);
      }
    };

    if (formData?.currency) {
      fetchRates();
    }
  }, [apiKey, formData.currency]);


  const formKeys = Object.keys(formData) as Array<keyof FormData>;
  const formatLabel = (label: string, type?: string) => {


    if (label === 'grossCost') {
      return 'Gross Cost (USD)';
    }
    return label
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());
  };

  // const handleClick = (index: number) => {
  //   const newPath = `/${pathElements.slice(0, index + 1).join('/')}`;
  //   // console.log('newPath', newPath)
  //   navigate(newPath);
  // };



  const shouldDisplayField = (key: keyof FormData) => {
    const fieldsToDisplay = ['url', 'proposalType', 'currency', 'grossCost', 'receivedAmount', 'projectDeadline', 'proposalDesc', 'proposal', 'client', 'cost'];

    if ((key === 'estimatedHours' || key === 'cost') && formData.proposalType !== 'hourly') {
      return false;
    }

    return !fieldsToDisplay.includes(key) || (formData.status === "completed" || formData.status === "hired");
  }

  const shouldDisplayField2 = (key: keyof FormData) => {
    const fieldsToDisplay = ['totalConnects'];

    const portal = data?.find(item => {
      const portalName = item?.portalName?.toLowerCase().trim();
      return portalName === "upwork";
    });

    const portal2 = data?.find(item => {
      const portalName = item?.portalName?.toLowerCase().trim();
      return (portalName === "upwork seo raft");
    });

    return !fieldsToDisplay.includes(key) || (formData.portal === portal?._id) || (formData.portal === portal2?._id);
  }


  useEffect(() => {

    if (data) {

      const portal = data?.find(item =>
        formData?.portal
          ? item?._id === formData.portal
          : item?.portalName === portalName
      );

      if (portal) {
        const calculatedPrice = formData?.grossCost - ((formData?.grossCost * portal?.charges) / 100);
        setPrice(calculatedPrice);

      } else {
        console.log("No matching portal found");
      }

      console.log("Data is null");
    }
  }, [portalName, data, formData.grossCost, formData.portal]);


  useEffect(() => {
    if (formData?.proposalType === 'hourly' && formData?.estimatedHours && formData?.cost) {

      setFormData((prevData) => ({
        ...prevData,
        grossCost: Number(formData.estimatedHours)! * formData?.cost!
      }));
    }
  }, [formData.proposalType, formData.estimatedHours, formData.cost]);

  useEffect(() => {
    if (formData?.estimatedHours && formData?.cost) {

      const [hoursStr, minutesStr] = formData?.estimatedHours?.split('.');

      const hours = Number(hoursStr);
      const minutes = Number(minutesStr) || 0;


      const totalHours = hours + (minutes / 60);

      const calculation = (totalHours * formData.cost * rates).toFixed(2)

      setFormData({ ...formData, grossCost: Number(calculation) })
    }
  }, [formData.estimatedHours, formData.cost, formData?.currency, rates])

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleCreateClient = async () => {
try {
      const res = await api.post('/portal/create_client', { clientName, creator: user?._id });
      if (res.data) {
        console.log('Client created successfully:', res.data);
        refetch()
        handleCloseModal();
      }
    } catch (err) {
      console.error('Error creating client:', err);
    }

  };

  const handleChangeClientName = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setClientName(event.target.value);
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
 e.preventDefault();

    if (formData.status !== "new" &&
      formData.status !== "pause" &&
      formData.status !== "rejected" &&
      formData.status !== "discussion" &&
      !formData.client) {
      toast({
        title: 'Kindly provide the client details to proceed.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      return;
    }
    try {
      let url = '/portal/update_proposal/' + id;
      let remianingCost = price;
      const res = await api.patch(url, { ...formData, remianingCost });
      if (res.data) {
        console.log('Client created successfully:', res.data);
        navigate(-1)
      }
    } catch (err) {
      console.error('Error creating client:', err);
    }
  };


  return (

    <>
      {loadingid ? <LoadingSpinner /> :
        <>
    
          <Modal isOpen={isOpen} onClose={handleCloseModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add Client</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl>
                  <Input
                    placeholder='Enter client name'
                    value={clientName}
                    onChange={handleChangeClientName}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={handleCreateClient}>
                  Create
                </Button>
                <Button onClick={handleCloseModal}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Box>
            <Box display={"flex"} justifyContent="space-between" alignItems={"center"}>
              <Heading as="h3" size="md" mb={4}>
                Hired Proposal Detail
              </Heading>
            </Box>
            <Box>
              <form onSubmit={handleSubmit}>
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  {formKeys.map((key, index) => (key !== 'status' && shouldDisplayField(key) && shouldDisplayField2(key) && (
                    <Box key={index}>

                      <FormControl id={key.toString()} >
                        <FormLabel style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>{formatLabel(key, formData.proposalType)}
                          <div>{(key === "client" && isEditable) && <Button colorScheme='teal' onClick={handleOpenModal}>Add Client</Button>}</div> </FormLabel>

                        {key === 'proposalDesc' ? (
                          <Textarea
                            required
                            name={key}
                            value={formData[key]}
                            onChange={handleChange}
                            disabled={!isEditable}
                          />
                        ) : key === 'projectDeadline' ? (
                          <Input

                            type="date"
                            name={key}
                            value={formData[key]}
                            onChange={handleChange}
                            min={new Date().toISOString().split('T')[0]}
                            disabled={!isEditable}

                          />
                        )

                          : key === 'jobCategory' ? (
                            <Select
                              required
                              isMulti
                              name="jobCategory"
                              isDisabled={!isEditable}
                              value={formData.jobCategory.map((categoryId) => ({
                                value: categoryId,
                                label: categoryData?.find((category) => category._id === categoryId)?.jobName || '',
                              }))}

                              options={categoryData?.map((category) => ({
                                value: category._id.toString(), // Ensure _id is converted to string if necessary
                                label: category.jobName,
                              }))}
                              onChange={(selectedOptions) => {
                                const values = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                                setFormData((prevData) => ({
                                  ...prevData,
                                  jobCategory: values,
                                }));
                              }}
                              placeholder="Select some categories..."
                              closeMenuOnSelect={false}

                            />

                          )
                            : key === 'client' ?
                              (
                                <FormControl key={key} >
                                  <Select
                                    isRequired
                                    menuPlacement='top'
                                    value={selected2}
                                    onChange={(selectedOption) => handleClient(selectedOption as any)}
                                    isDisabled={!isEditable}
                                    name="colors"
                                    options={clientData?.map((el) => ({
                                      value: el._id,
                                      label: el.clientName,
                                    })) as any}

                                    placeholder="Select client"
                                    closeMenuOnSelect={false}
                                    size="md"

                                  />
                                </FormControl>

                              )
                              : key === 'proposalType' ? (
                                <Shivam
                                  required
                                  name={key}
                                  value={formData[key]}
                                  onChange={handleChange}
                                  placeholder={`Select ${formatLabel(key)}`}
                                  disabled={!isEditable}
                                >
                                  <option value="fixed">Fixed</option>
                                  <option value="hourly">Hourly</option>
                                </Shivam>
                              ) : key === 'companyName' ? (
                                <Shivam
                                  name={key}
                                  value={formData[key]}
                                  onChange={handleChange}
                                  placeholder={`Select ${formatLabel(key)}`}
                                  required
                                  disabled={!isEditable}
                                >
                                  <option value="AWS">Aron Web Solutions</option>
                                  <option value="SEO">SEO Raft</option>
                                </Shivam>
                              )
                                : key === 'portal' ? (
                                  <Shivam
                                    name={key}
                                    value={formData[key]}
                                    onChange={handleChange}
                                    placeholder={`Select ${formatLabel(key)}`}
                                    required
                                    disabled={!isEditable}
                                  >
                                    {!loading && data && data.map((portal) => (
                                      <option key={portal._id} value={portal._id}>
                                        {portal.portalName}
                                      </option>
                                    ))}
                                  </Shivam>
                                ) : key === 'profile' ? (
                                  <Shivam
                                    name={key}
                                    value={formData[key]}
                                    onChange={handleChange}
                                    placeholder={`Select ${formatLabel(key)}`}
                                    required
                                    disabled={!isEditable}
                                  >

                                    {/* {(user?.role === "member" || user?.role === "manager") && userdata?.profilesAllocated?.map((profile: any) => (
                                    profile.portal === formData.portal && <option key={profile._id} value={profile._id}>
                                      {profile.profileName}
                                    </option>
                                  ))
                                  } */}

                                    {(user?.role === "admin" || user?.role === "manager") ?

                                      profileData && profileData.map((profile: any) => (
                                        profile?.portal?._id === formData.portal && <option key={profile._id} value={profile._id}>
                                          {profile.profileName}
                                        </option>
                                      ))

                                      :
                                      userdata?.profilesAllocated?.map((profile: any) => (
                                        profile.portal === formData.portal && <option key={profile._id} value={profile._id}>
                                          {profile.profileName}
                                        </option>
                                      ))
                                    }

                                  </Shivam>
                                )
                                  : key === 'totalConnects' ?
                                    (

                                      <Input
                                        type="text"
                                        name={key}
                                        // readOnly={id ? true : false}
                                        // disabled={id ? true : false}
                                        required
                                        value={formData[key]}
                                        onChange={handleChange}
                                        disabled={!isEditable}
                                      />
                                    ) : key === 'grossCost' ?
                                      (
                                        <>
                                          <Input
                                            required
                                            type="number"
                                            name={key}
                                            value={formData[key]}
                                            onChange={handleChange}
                                            flex="1"
                                            disabled={!isEditable}
                                          />

                                          <Box marginLeft={2}>Remaining Cost: ${price?.toFixed(2)}</Box>


                                        </>

                                      ) :
                                      key === 'currency' ?
                                        (
                                          <FormControl key={key} isRequired>
                                            {/* <FormLabel>currency</FormLabel> */}
                                            <Shivam
                                              placeholder='Select currency'
                                              name={key}
                                              disabled={id ? true : false}
                                              onChange={handleChange}
                                              value={formData[key]}


                                            >
                                              {["USD", "INR", "AUD", "GBP", "EUR", "CAD", "NZD", "SGD",].map(currency => (
                                                <option key={currency} value={currency}>{currency}</option>
                                              ))}
                                            </Shivam>
                                          </FormControl>
                                        ) :
                                        (


                                          <Input
                                            required={!((key === 'proposalTitle' || key === 'proposalLink') && (selectedPortal?.portalName === "Direct" || selectedPortal?.portalName === "Direct Paypal"))}
                                            // required
                                            type="text"
                                            name={key}
                                            value={formData[key]}
                                            onChange={handleChange}
                                            placeholder={((key === 'proposalTitle' || key === 'proposalLink') && (selectedPortal?.portalName === "Direct" || selectedPortal?.portalName === "Direct Paypal"))
                                              ? `Optional`
                                              : undefined}
                                            disabled={!isEditable}
                                          />
                                        )}
                      </FormControl>

                    </Box>
                  )
                  ))}

                  <Box>
                    <FormControl id="status">
                      <FormLabel>{t("status")}</FormLabel>
                      <Shivam
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        disabled={!isEditable}
                      >
                        <option value="new">New</option>
                        <option value="completed">Completed</option>
                        <option value="hired">Hired</option>
                        <option value="discussion">Discussion</option>
                        <option value="pause">Pause</option>
                        <option value="rejected">Rejected</option>
                      </Shivam>
                    </FormControl>
                  </Box>
                </Grid>
<Stack spacing={4} direction="row" align="center" mt={6}>
  {!isEditable ? (
    <Button
      bg="#F36E21"  
      color="white"
      variant="solid"
      _hover={{ color: "black", bg: "#dfd6d68a" }}
      type="button"
      onClick={handleEditClick}
    >
      {t("edit")}
    </Button>
  ) : (
    <Button
      bg="#F36E21"
      color="white"
      variant="solid"
      _hover={{ color: "black", bg: "#dfd6d68a" }}
      type="submit"
    >
      {t("update_proposal")}
    </Button>
  )}
  
  <Button
    colorScheme="gray"
    variant="solid"
    type="button"
    onClick={handleCancelClick}
  >
    {t("cancel")}
  </Button>
</Stack>
</form>
            </Box>
          </Box>
        </>}
    </>

  )
}
import React, { useState, useEffect, useContext } from 'react';
import { Flex, FormControl, Heading, Input, Select, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../components/UI/button/Button';
import LoadingSpinner from '../components/UI/loadingSpinner/LoadingSpinner';
import CustomTable from '../components/tables/customTable/CustomTable';
import { IProposalTable } from '../interfaces/Itable';
import { customersHeader } from '../constants/tables';
import useDataFetch from '../hook/useDataFetch';
import LoginContext from '../store/loginContext';

const Proposal = () => {
  const { t } = useTranslation();
  const { user } = useContext(LoginContext);
  const navigate = useNavigate();
  // const portalProposalUrl = user.role === "admin" ? `${process.env.REACT_APP_API_URL}/api/portal/proposals`: `${process.env.REACT_APP_API_URL}/api/portal/proposals?id=${user?._id}`;
  const portalProposalUrl = 
  user.role === "admin" || user.role === "manager" 
    ? `${process.env.REACT_APP_API_URL}/api/portal/proposals` 
    : `${process.env.REACT_APP_API_URL}/api/portal/proposals?id=${user?._id}`;

  const { data, error, loading ,refetch} = useDataFetch<IProposalTable[]>(portalProposalUrl);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterTerm, setFilterTerm] = useState('');  
  const [jobCategory, setJobCategory] = useState('');
  const [selected, setSelected] = useState<string>("");
  const [filteredData, setFilteredData] = useState<IProposalTable[]>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');


   // Calculate start and end dates for current and previous month
   const getInitialDateRange = () => {
    const today = new Date();
    const firstDayOfPreviousMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    return { start: firstDayOfPreviousMonth, end: today };
  };


  useEffect(() => {
 if (selected) refetch()
 }, [selected])



 
//   useEffect(() => {
//     if (data) {
     
//       let filtered = data?.filter(
//         (item) =>
        
//           item.client?.clientName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           item.creator?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           item.proposalLink?.toLowerCase().includes(searchTerm.toLowerCase())
//       );

//       if (filterTerm) {
//         filtered = filtered.filter((item) =>
//           item.status.toLowerCase().includes(filterTerm.toLowerCase())
//         );
//       }

// //       if (jobCategory) {
// //         filtered = filtered?.filter((item) =>{
// // console.log(item,"ITREM DZTA")
// //           item?.jobCategory?.jobName?.toLowerCase().includes(jobCategory.toLowerCase())}
// //         );
// //       }

//       if (jobCategory) {
//         filtered = filtered?.filter((item) =>
//           item?.jobCategory?.some((category) =>{
         
//            return category?.jobName?.toLowerCase().includes(jobCategory.toLowerCase())}
//           )
//         );
//       }
      
      
//       if (startDate) {
       
//         filtered = filtered.filter((item) =>
//           new Date(item.createdAt) >= new Date(startDate)
//         );
       
//       }

//       if (endDate) {
        
//         // console.log(endDate)
//         // filtered = filtered.filter((item) =>
//         //   new Date(item.createdAt) <= new Date(endDate)
//         // );
//         // console.log(filtered,"end")
  
//         // Adjust the endDate to include the entire day
//         const adjustedEndDate = new Date(endDate);
//         adjustedEndDate.setHours(23, 59, 59, 999);
//         filtered = filtered.filter((item) => new Date(item.createdAt) <= adjustedEndDate);
     
//       }

//       setFilteredData(filtered);
//     }
//   }, [data, searchTerm, filterTerm, jobCategory, startDate, endDate]);


useEffect(() => {
  if (data) {
 const { start, end } = getInitialDateRange();

    // Filter data based on search terms, status, job category, and date range
    let filtered = data.filter(
      (item) =>
        item.client?.clientName
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.creator?.name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.proposalLink?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filterTerm) {
      filtered = filtered.filter((item) =>
        item.status.toLowerCase().includes(filterTerm.toLowerCase())
      );
    }

    if (jobCategory) {
      filtered = filtered?.filter((item) =>
        item?.jobCategory?.some((category) =>
          category?.jobName?.toLowerCase().includes(jobCategory.toLowerCase())
        )
      );
    }

    // initially filtered proposals by date
    if (!startDate && !endDate) {
      filtered = filtered.filter(
        (item) =>
          new Date(item.createdAt) >= start && new Date(item.createdAt) <= end
      );
    }

    // proposals date filtered by users
    if (startDate) {
      filtered = filtered.filter(
        (item) => new Date(item.createdAt) >= new Date(startDate)
      );
    }

    if (endDate) {
      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setHours(23, 59, 59, 999);
      filtered = filtered.filter(
        (item) => new Date(item.createdAt) <= adjustedEndDate
      );
    }

    setFilteredData(filtered);
  }
}, [data, searchTerm, filterTerm, jobCategory, startDate, endDate]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <p>Error loading proposals</p>; // Handle the error more appropriately
  }

  return (
    <>
      <section>
        <Flex mb={4} alignItems="center" justify="space-between">
          <Heading color="#F36E21">{t('proposals')}</Heading>
          <Button outline onClick={() => navigate('/proposals/create')}>
            {t('createProposal')}
          </Button>
        </Flex>
        <Flex mb={4} gap={4} alignItems="center" justify="space-between">
          <Flex gap={4}>
            <Input

            
              type="text"
              w={'fit-content'}
              placeholder="Search by link/Client or Username"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Select
              w={'fit-content'}
              placeholder="Select status"
              value={filterTerm}
              onChange={(e) => setFilterTerm(e.target.value)}
            >
              <option value="hired">Hired</option>
              <option value="completed">Completed</option>
              <option value="discussion">Discussion</option>
              <option value="new">New</option>
              <option value="rejected">Rejected</option>
              <option value="pause">Pause</option>
            </Select>
            <Select
              w={'fit-content'}
              placeholder="Select job category"
              value={jobCategory}
              onChange={(e) => setJobCategory(e.target.value)}
            >
              <option value="designing">Designing</option>
              <option value="development">Development</option>
              <option value="marketing">Digital Marketing</option>
              <option value="graphics">Graphics</option>
            </Select>
          </Flex>
          <Flex gap={2} alignItems="center">
            <Text>From</Text>
            <Input
              type="date"
              w={'fit-content'}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <Text>To</Text>
            <Input
              type="date"
              w={'fit-content'}
              value={endDate}
              min={startDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Flex>
        </Flex>
        {filteredData.length > 0 ? (
          <CustomTable key={Date.now()} headData={customersHeader} bodyData={filteredData} limit={10} setSelected={setSelected}/>
        ) : (
          <p>No matching proposals found</p>
        )}
      </section>
    </>
  );
};

export default Proposal;


/* eslint-disable no-dupe-keys */
import {
  Box,
  Button,
 FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select as Shivam,
  Stack,
  Textarea,
  useToast
} from '@chakra-ui/react';
import { Select } from "chakra-react-select";
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useDataFetch from '../../hook/useDataFetch';
import { IClientsTable, IJobCategoryTable, IPortalProfileTable, IPortalTable } from '../../interfaces/Itable';
import LoginContext from '../../store/loginContext';
import api from '../../utils/api';
import LoadingSpinner from '../UI/loadingSpinner/LoadingSpinner';
import axios from 'axios';





interface FormData {
  companyName: string;
  portal: string;
  profile: string;
  jobCategory: string[];
  proposalTitle: string;
  proposalLink: string;
  // url: string;
  proposalType: string;
  currency: string;
  cost: number;
  estimatedHours: string;
  grossCost: number;
  receivedAmount: string;
  projectDeadline: string;
  totalConnects: string;
  proposalDesc: string;
  // proposal: string;
  client: string;
  status: string;

}

interface ErrorFormData {
  companyName: string;
  portal: string;
  profile: string;
  jobCategory: string;
  proposalTitle: string;
  proposalLink: string;
  // url: string;
  proposalType: string;
  estimatedHours: string;
  grossCost: number;
  currency: string;
  cost: number;
  receivedAmount: string;
  projectDeadline: string;
  totalConnects: string;
  proposalDesc: string;
  // proposal: string;
  client: string;
  status: string;

}

const CreateProposal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(LoginContext);
  const { id } = useParams();
  const [userdata, setUserdata] = useState(user);
  const toast=useToast();
  const { pathname } = useLocation();
  const PortalUrl = `${process.env.REACT_APP_API_URL}/api/portal`
  const PortalPorfileUrl = `${process.env.REACT_APP_API_URL}/api/portal/profile`
  const ClientUrl = `${process.env.REACT_APP_API_URL}/api/portal/clients`
  const PortalCategoryUrl = `${process.env.REACT_APP_API_URL}/api/portal/category`
  const url = `${process.env.REACT_APP_API_URL}/api/users/${user._id}`;
 

  const [loadingid, setLoadingID] = useState(false)
  const { data, loading } = useDataFetch<IPortalTable[]>(PortalUrl);
  const { data: clientData,  refetch } = useDataFetch<IClientsTable[]>(ClientUrl);
  const { data: profileData } = useDataFetch<IPortalProfileTable[]>(PortalPorfileUrl);
  const { data: categoryData, } = useDataFetch<IJobCategoryTable[]>(PortalCategoryUrl);
  const [selectedPortal, setSelectedPortal] = useState<IPortalTable | null>(null);
  const [portalName, setportalName] = useState({})
  const [rates, setRates] = useState(0);
  const apiKey = '98a265629f6f37c3eaf2e783';

  const [selected2, setSelected2] = useState<{
    value: string;
    label: string;
  }>({
    value: '',
    label: ""
  });

  const [price, setPrice] = useState<number | null>(null);


  const [formData, setFormData] = useState<FormData>({
    companyName: '',
    portal: '',
    profile: "",
    jobCategory: [],
    proposalTitle: '',
    proposalLink: '',
    // url: '',
    proposalType: '',
    currency: '',
    estimatedHours: "0",
    cost: 0,
    grossCost: 0,
    receivedAmount: '',
    projectDeadline: new Date().toISOString().split('T')[0],
    totalConnects: '',
    proposalDesc: '',
    // proposal: '',
    client: '',
    status: 'new',

  });

  const [error, setError] = useState<ErrorFormData>({
    companyName: '',
    portal: '',
    profile: '',
    jobCategory: '',
    proposalTitle: '',
    proposalLink: '',
    // url: '',
    proposalType: '',
    estimatedHours: "0",
    grossCost: 0,
    currency: '',
    cost: 0,
    receivedAmount: '',
    projectDeadline: '',
    totalConnects: '',
    proposalDesc: '',
    // proposal: '',
    client: '',
    status: '',

  });



  // ______________________getting the updateduserdat_____________________

  const fetchData = async () => {
    try {

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json()


      if (result) {
        setUserdata(result)

      }

    } catch (error: unknown) {
      console.log(error)
    }

    
  }



  useEffect(() => {
    fetchData();
  }, [])


  useEffect(() => {

    async function getData() {
      try {

        setLoadingID(true)
        const res = await api.get('/portal/proposal/' + id);
        setportalName(res?.data?.portal?.portalName)

        setLoadingID(false)
        if (!res.data) {
          return
        }
        const {
          companyName,
          portal,
          jobCategory,
          profile,
          proposalTitle,
          proposalLink,
          // url,
          proposalType,
          currency,
          cost,
          estimatedHours,
          grossCost,
          receivedAmount,
          projectDeadline,
          totalConnects,
          proposalDesc,
          // proposal,
          client,
          status,

        } = res.data;
        setSelected2({
          value: client?._id,
          label: client?.clientName
        })
        // Convert jobCategory to an array of _id strings if it's an array
        const jobCategoryIds = Array.isArray(jobCategory)
          ? jobCategory.map((category: any) => category._id.toString()) // Ensure _id is converted to string if necessary
          : jobCategory?._id
            ? [jobCategory._id.toString()] // Ensure _id is converted to string if necessary
            : [];



        setFormData({
          companyName: companyName || '',
          portal: portal?._id || '',
          profile: profile?._id || '',

          // jobCategory: jobCategory?._id || '',
          jobCategory: jobCategoryIds,
          proposalTitle,
          proposalLink,
          // url,
          proposalType,
          currency,
          estimatedHours,
          cost,
          
          grossCost,
          receivedAmount,
          projectDeadline: new Date(projectDeadline).toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
          totalConnects,
          proposalDesc,
          // proposal,
          client: client?._id || '',
          status,

        });
      } catch (err) {
        console.log(err);
      }
    }
    if (id) getData();
  }, [id]);


  useEffect(() => {
    if (formData?.portal && data) {
      const selectedPortal = data.find((portal) => portal._id === formData?.portal);
      if (selectedPortal) {
        setSelectedPortal(selectedPortal);
      }
    }
  }, [formData?.portal, data])


  const pathElements = pathname.split('/').filter((el) => el !== '')


  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {

    const { name, value } = e.target;

    setFormData((prevData) => {

      if (name === 'proposalType' && value === 'fixed') {
        return {
          ...prevData,
          [name]: value,
          estimatedHours: "0",
          cost: 0,
        };
      }
      return {
        ...prevData,
        [name]: value,
      };
    });
  };



  const handleClient = (value: { value: string; label: string }) => {
    setFormData((prevData) => ({
      ...prevData,
      "client": value.value
    }));
    setSelected2(value)


  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if( formData.status !== "new" &&
      formData.status !== "pause" &&
      formData.status !== "rejected" &&
      formData.status !== "discussion" &&
      !formData.client){
      toast({
        title: 'Kindly provide the client details to proceed.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
return;
    }
    try {
      let url = id ? '/portal/update_proposal/' + id : '/portal/create_proposal';
      let creator = user._id;
      let remianingCost = price;
      const res = id ? await api.patch(url, { ...formData, remianingCost }) : await api.post(url, { ...formData, creator, remianingCost });
      if (res.data) {
        console.log('Client created successfully:', res.data);
        navigate("/proposals")
      }
    } catch (err) {
      console.error('Error creating client:', err);
    }
  };



  const formKeys = Object.keys(formData) as Array<keyof FormData>;
  const formatLabel = (label: string, type?: string) => {

    // if (label === 'cost' && type === "hourly") {
    //   return "Hourly Cost"
    // }
    if (label === 'grossCost') {
      return 'Gross Cost (USD)';
    }
    return label
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());
  };

  const handleClick = (index: number) => {
    const newPath = `/${pathElements.slice(0, index + 1).join('/')}`;
    // console.log('newPath', newPath)
    navigate(newPath);
  };



  const shouldDisplayField = (key: keyof FormData) => {
    const fieldsToDisplay = ['url', 'proposalType', 'currency', 'grossCost', 'receivedAmount', 'projectDeadline', 'proposalDesc', 'proposal', 'client', 'cost'];

    if ((key === 'estimatedHours' || key === 'cost') && formData.proposalType !== 'hourly') {
      return false;
    }

    return !fieldsToDisplay.includes(key) || (formData.status === "completed" || formData.status === "hired");
  }

  const shouldDisplayField2 = (key: keyof FormData) => {
    const fieldsToDisplay = ['totalConnects'];

    const portal = data?.find(item => {
      const portalName = item?.portalName?.toLowerCase().trim();
      return portalName === "upwork";
    });

    const portal2 = data?.find(item => {
      const portalName = item?.portalName?.toLowerCase().trim();
      return (portalName === "upwork seo raft");
    });

    return !fieldsToDisplay.includes(key) || (formData.portal === portal?._id) || (formData.portal === portal2?._id);
  }


  const [isOpen, setIsOpen] = useState(false);
  const [clientName, setClientName] = useState('');

  const [profiles, setProfiles] = useState([]);




  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleCreateClient = async () => {


    try {
      const res = await api.post('/portal/create_client', { clientName, creator: user?._id });
      if (res.data) {
        console.log('Client created successfully:', res.data);
        refetch()
        handleCloseModal();
      }
    } catch (err) {
      console.error('Error creating client:', err);
    }

  };

  const handleChangeClientName = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setClientName(event.target.value);
  };



  useEffect(() => {

    if (data) {

      const portal = data?.find(item =>
        formData?.portal
          ? item?._id === formData.portal
          : item?.portalName === portalName
      );
      // const portal = data?.find(item => item?._id === formData?.portal);

      if (portal) {
        const calculatedPrice = formData?.grossCost - ((formData?.grossCost * portal?.charges) / 100);
        setPrice(calculatedPrice);

      } else {
        console.log("No matching portal found");
      }

      console.log("Data is null");
    }
  }, [portalName, data, formData.grossCost, formData.portal]);


  useEffect(() => {
    if (formData?.proposalType === 'hourly' && formData?.estimatedHours && formData?.cost) {

      setFormData((prevData) => ({
        ...prevData,
        grossCost: Number(formData.estimatedHours)! * formData?.cost!
      }));
    }
  }, [formData.proposalType, formData.estimatedHours, formData.cost]);

  useEffect(() => {
    if (formData?.estimatedHours && formData?.cost) {

      const [hoursStr, minutesStr] = formData?.estimatedHours?.split('.');

      const hours = Number(hoursStr);
      const minutes = Number(minutesStr) || 0;


      const totalHours = hours + (minutes / 60);

      const calculation = (totalHours * formData.cost * rates).toFixed(2)

      setFormData({ ...formData, grossCost: Number(calculation) })
    }
  }, [formData.estimatedHours, formData.cost, formData?.currency, rates])



  useEffect(() => {
    // Fetch exchange rates on component mount
    const fetchRates = async () => {
      try {
        const response = await axios.get(`https://v6.exchangerate-api.com/v6/98a265629f6f37c3eaf2e783/latest/${formData?.currency}`);
        setRates(response.data.conversion_rates.USD);

      } catch (error) {
        console.error('Error fetching exchange rates', error);
      }
    };

    if (formData?.currency) {
      fetchRates();
    }
  }, [apiKey, formData.currency]);



  return (
    <>
      {loadingid ? <LoadingSpinner /> : <>
        <Modal isOpen={isOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Client</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <Input
                  placeholder='Enter client name'
                  value={clientName}
                  onChange={handleChangeClientName}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={handleCreateClient}>
                Create
              </Button>
              <Button onClick={handleCloseModal}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Button mb={4} onClick={() => navigate(-1)}>
        Go Back
      </Button>
        <Box mb={10} mt={-5}>
          {pathElements.slice(0, 1).map((element, index) => (
            <span key={index} style={{ color: "black" }}>
              <Link to={`/${pathElements.slice(0, index + 1).join('/')}`} onClick={() => handleClick(index)}>
                {element}
              </Link>
              {index !== pathElements.length - 1 && ' / '}
            </span>
          ))}
          <span>{pathElements[1]}</span>
        </Box>
        <Box>
          <Box display={"flex"} justifyContent="space-between" alignItems={"center"}>
            <Heading as="h3" size="md" mb={4}>
              {id ? "Update Proposal" : t("create_proposal")}
            </Heading>
          </Box>
          <Box>
            <form onSubmit={handleSubmit}>
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                {formKeys.map((key, index) => (key !== 'status' && shouldDisplayField(key) && shouldDisplayField2(key) && (
                  <Box key={index}>

                    <FormControl id={key.toString()} >
                      <FormLabel style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>{formatLabel(key, formData.proposalType)}
                        <div>{key === "client" && <Button colorScheme='teal' onClick={handleOpenModal}>Add Client</Button>}</div> </FormLabel>

                      {key === 'proposalDesc' ? (
                        <Textarea
                          required
                          name={key}
                          value={formData[key]}
                          onChange={handleChange}
                        />
                      ) : key === 'projectDeadline' ? (
                        <Input

                          type="date"
                          name={key}
                          value={formData[key]}
                          onChange={handleChange}
                          min={new Date().toISOString().split('T')[0]}

                        />
                      )
                        // : key === 'jobCategory' ? (
                        //   <Shivam

                        //     name={key}
                        //     value={formData[key]}
                        //     onChange={handleChange}
                        //     placeholder={`Select ${formatLabel(key)}`}
                        //   >
                        //     {selectedjob && selectedjob.map(
                        //       (profile) => (
                        //         <option key={profile.jobCategory._id} value={profile.jobCategory._id}>
                        //           {profile.jobCategory.jobName}
                        //         </option>
                        //       )
                        //     )}
                        //   </Shivam>
                        // ) 

                        : key === 'jobCategory' ? (
                          <Select
                            required
                            isMulti
                            name="jobCategory"
                            value={formData.jobCategory.map((categoryId) => ({
                              value: categoryId,
                              label: categoryData?.find((category) => category._id === categoryId)?.jobName || '',
                            }))}
                            options={categoryData?.map((category) => ({
                              value: category._id.toString(), // Ensure _id is converted to string if necessary
                              label: category.jobName,
                            }))}
                            onChange={(selectedOptions) => {
                              const values = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                              setFormData((prevData) => ({
                                ...prevData,
                                jobCategory: values,
                              }));
                            }}
                            placeholder="Select some categories..."
                            closeMenuOnSelect={false}
                          />

                        )
                          : key === 'client' ?
                            (
                              <FormControl key={key} >
                                <Select
                                  isRequired
                                  menuPlacement='top'
                                  value={selected2}
                                  onChange={(selectedOption) => handleClient(selectedOption as any)}
                                  name="colors"
                                  options={clientData?.map((el) => ({
                                    value: el._id,
                                    label: el.clientName,
                                  })) as any}

                                  placeholder="Select client"
                                  closeMenuOnSelect={false}
                                  size="md"
                                />
                              </FormControl>

                            )
                            : key === 'proposalType' ? (
                              <Shivam
                                required
                                name={key}
                                value={formData[key]}
                                onChange={handleChange}
                                placeholder={`Select ${formatLabel(key)}`}
                              >
                                <option value="fixed">Fixed</option>
                                <option value="hourly">Hourly</option>
                              </Shivam>
                            ) : key === 'companyName' ? (
                              <Shivam
                                name={key}
                                value={formData[key]}
                                onChange={handleChange}
                                placeholder={`Select ${formatLabel(key)}`}
                                required
                              >
                                <option value="AWS">Aron Web Solutions</option>
                                <option value="SEO">SEO Raft</option>
                              </Shivam>
                            )
                              : key === 'portal' ? (
                                <Shivam
                                  name={key}
                                  value={formData[key]}
                                  onChange={handleChange}
                                  placeholder={`Select ${formatLabel(key)}`}
                                  required
                                >
                                  {!loading && data && data.map((portal) => (
                                    <option key={portal._id} value={portal._id}>
                                      {portal.portalName}
                                    </option>
                                  ))}
                                </Shivam>
                              ) : key === 'profile' ? (
                                <Shivam
                                  name={key}
                                  value={formData[key]}
                                  onChange={handleChange}
                                  placeholder={`Select ${formatLabel(key)}`}
                                  required
                                >

                                  {/* {(user?.role === "member" || user?.role === "manager") && userdata?.profilesAllocated?.map((profile: any) => (
                                    profile.portal === formData.portal && <option key={profile._id} value={profile._id}>
                                      {profile.profileName}
                                    </option>
                                  ))
                                  } */}

                                  {(user?.role === "admin" || user?.role === "manager")?

                                    profileData && profileData.map((profile: any) => (
                                      profile?.portal?._id === formData.portal && <option key={profile._id} value={profile._id}>
                                        {profile.profileName}
                                      </option>
                                    ))

                                    :
                                    userdata?.profilesAllocated?.map((profile: any) => (
                                      profile.portal === formData.portal && <option key={profile._id} value={profile._id}>
                                        {profile.profileName}
                                      </option>
                                    ))
                                  }

                                </Shivam>
                              )
                                : key === 'totalConnects' ?
                                  (

                                    <Input
                                      type="text"
                                      name={key}
                                      // readOnly={id ? true : false}
                                      disabled={id ? true : false}
                                      required
                                      value={formData[key]}
                                      onChange={handleChange}
                                    />
                                  ) : key === 'grossCost' ?
                                    (
                                      <>
                                        <Input
                                          required
                                          type="number"
                                          name={key}
                                          value={formData[key]}
                                          onChange={handleChange}
                                          flex="1"
                                        />

                                        <Box marginLeft={2}>Remaining Cost: ${price?.toFixed(2)}</Box>


                                      </>

                                    ) :
                                    key === 'currency' ?
                                      (
                                        <FormControl key={key} isRequired>
                                          {/* <FormLabel>currency</FormLabel> */}
                                          <Shivam
                                            placeholder='Select currency'
                                            name={key}
                                            disabled={id ? true : false}
                                            onChange={handleChange}
                                            value={formData[key]}

                                          >
                                            {["USD", "INR", "AUD", "GBP", "EUR", "CAD", "NZD", "SGD",].map(currency => (
                                              <option key={currency} value={currency}>{currency}</option>
                                            ))}
                                          </Shivam>
                                        </FormControl>
                                      ) :
                                      (


                                        <Input
                                          required={!((key === 'proposalTitle' || key === 'proposalLink') && (selectedPortal?.portalName === "Direct" || selectedPortal?.portalName === "Direct Paypal"))}
                                          // required
                                          type="text"
                                          name={key}
                                          value={formData[key]}
                                          onChange={handleChange}
                                          placeholder={((key === 'proposalTitle' || key === 'proposalLink') && (selectedPortal?.portalName === "Direct" || selectedPortal?.portalName === "Direct Paypal"))
                                            ? `Optional`
                                            : undefined}
                                        />
                                      )}
                    </FormControl>

                  </Box>
                )
                ))}

                <Box>
                  <FormControl id="status">
                    <FormLabel>{t("status")}</FormLabel>
                    <Shivam
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                    >
                      <option value="new">New</option>
                      <option value="completed">Completed</option>
                      <option value="hired">Hired</option>
                      <option value="discussion">Discussion</option>
                      <option value="pause">Pause</option>
                      <option value="rejected">Rejected</option>
                    </Shivam>
                  </FormControl>
                </Box>
              </Grid>
              <Stack spacing={4} direction="row" align="center" mt={6}>
                <Button bg="#F36E21" color="white" variant="solid" _hover={{ color: "black", bg: "#dfd6d68a" }} type="submit">
                  {id ? t("update_proposal") : t("create_proposal")}
                </Button>
                <Button colorScheme="gray" variant="solid" onClick={() => navigate("/proposals")}>
                  {t("cancel")}
                </Button>
              </Stack>
            </form>
          </Box>
        </Box>
      </>}
    </>
  );
};



export default CreateProposal;